import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'app/services/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { ObraSocialService } from 'app/services/obra-social.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-obra-social-editar',
  templateUrl: './obra-social-editar.component.html',
  styleUrls: ['./obra-social-editar.component.scss']
})
export class EditarObraSocialComponent implements OnInit {

  
  //
  usuarioPanel:any;
  obraSocial:any;
  editar:boolean = true;


  //
  constructor(
    private actRoute:ActivatedRoute,
    private router:Router,
    private spinnerService:SpinnerService,
    private usuarioPanelService:UsuarioPanelService,
    private obraSocialService:ObraSocialService,
  ) { 
    this.spinnerService.mostrarSpinner();
    let id = this.actRoute.snapshot.paramMap.get("id");
    this.usuarioPanelService.profileUser().pipe(
      map(data => {
        this.usuarioPanel = data;
      }),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        this.usuarioPanelService.logout();
        return of({ results: null });
      }),
      switchMap(data => this.obraSocialService.mostrar(id).pipe(
        map(data => { 
          this.obraSocial = data;
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return of({ results: null });
      }),
    )
    .subscribe(
      (data) => console.log('data',data),
      (error) => console.error('Error al llamar a la API:', error)
    );
  }


  //
  ngOnInit(): void {}


}
