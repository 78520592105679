<div class="espacioHeader"></div>
<div class="headerPanel mat-elevation-z4">
    <div>
        <button class="button" mat-mini-fab aria-label="icon" (click)="botonDesplegableShadow()">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
    <div>
        <img src="assets/img/ico_campana.png" class="campana"/>
        <img [src]="imagen" class="icon" [matMenuTriggerFor]="appMenu"/>
    </div>
    <mat-menu #appMenu="matMenu" class="dropdown">
        <mat-nav-list>
            <a mat-list-item class="buttonDash" [routerLink]="['/seccion-paciente/editar-perfil']">Editar Perfil</a>
            <a mat-list-item class="buttonDash" (click)="logout()">Salir</a>
        </mat-nav-list>
    </mat-menu>
</div>
