import { Component, OnInit } from '@angular/core';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { SpinnerService } from 'app/services/spinner.service';
import { ObraSocialService } from 'app/services/obra-social.service';
import { TelefonoPaisService } from 'app/services/telefono-pais.service';
import { CentroMedicoService } from 'app/services/centro-medico.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-centro-medico',
  templateUrl: './centro-medico.component.html',
  styleUrls: ['./centro-medico.component.scss']
})
export class CentroMedicoComponent implements OnInit {

  
  //
  usuarioPanel:any;
  editar:boolean = false;
  response:any;
  centroMedico:any;
  lista:any;


  //
  constructor(
    private usuarioPanelService:UsuarioPanelService,
    private spinnerService:SpinnerService,
    private centroMedicoService:CentroMedicoService,
    private telefonoPaisService:TelefonoPaisService
  ) { 
    this.spinnerService.mostrarSpinner();
    this.usuarioPanelService.profileUser().pipe(
      map(data => {
        this.usuarioPanel = data;
      }),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        this.usuarioPanelService.logout();
        return of({ results: null });
      }),
      switchMap(data => this.centroMedicoService.mostrarCentroMedico().pipe(
        map(data => { 
          this.response = data;
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return of({ results: null });
      }),
    )
    .subscribe(
      (data) => {
        console.log('data',data);
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
      }
    );
  }


  //
  ngOnInit(): void {}


}
