<div class="dashboardShadow" #shadow (click)="quitarShadow()"></div>
<div class="dashboardContent mat-elevation-z4" #dashboardContent>
    <div class="init">
        <div class="divImg">
            <img src="{{imagen}}" class="img"/>
        </div>        
        <mat-nav-list>
            <a mat-list-item [routerLink]="['/seccion-medico/home-medico']">
                <img src="assets/img/ico_home.svg" class="icon"/> 
                <span class="nav-caption">Mis pacientes</span>
            </a>
            <a *ngIf="turnoHabilitado == 1" mat-list-item [routerLink]="['/seccion-medico/turnos']">
                <img src="assets/img/ico_documento.svg" class="icon"/> 
                <span class="nav-caption">Mis Turnos</span>
            </a>
            <a *ngIf="turnoHabilitado != 1" mat-list-item class="opacity">
                <img src="assets/img/ico_documento.svg" class="icon"/> 
                <span class="nav-caption">Mis Turnos</span>
            </a>
            <a mat-list-item (click)="contactoDialog()">
                <img src="assets/img/ico_consulta.svg" class="icon"/> 
                <span class="nav-caption">{{titulo_contacto}}</span>
            </a>
            <div class="back">
                <a mat-list-item (click)="quitarShadow()">
                    <img src="assets/img/ico_volver2.png" class="icon"/> 
                    <span class="nav-caption">Volver</span>
                </a>
            </div>
        </mat-nav-list>
    </div>
</div>
