import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CanActivate, Router } from '@angular/router';
import { ObraSocial } from 'app/models/obraSocial.interface';
import { Header } from 'primeng/api';
import { UsuarioPanel } from 'app/models/usuarioPanel.interface';
import { GlobalVariable } from 'app/global/global';
import { ContentObserver } from '@angular/cdk/observers';
import * as CryptoJS from 'crypto-js';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import padZeroPadding from 'crypto-js/pad-zeropadding';


@Injectable({
  providedIn: 'root'
})
export class UsuarioPanelService {


  //
  url:string = GlobalVariable.BASE_API_URL+"api/usuario-panel/";
  imagen:string;
  rol:string;


  //
  constructor(
    private http: HttpClient,
    private router:Router,
  ) { }


  //
  listar():Observable<any>{
    return this.http.get(this.url+"listar");
  }


  //
  datosInicio():Observable<any>{
    return this.http.get(this.url+"datos-inicio");
  }

  //
  cantidad():Observable<any>{
    return this.http.get(this.url+"cantidad");
  }


  //
  crear(nameImage:string,usuarioPanel:UsuarioPanel,file:any):Observable<any>{
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    if(file == null) {
      
    }
    else{
      formData.append("image", file);
    }
    formData.append("nombreCompleto",usuarioPanel.nombreCompleto.trim());
    formData.append("nombreUsuario",usuarioPanel.nombreUsuario.trim());
    formData.append("email",usuarioPanel.email.trim());
    formData.append("telPais",usuarioPanel.telPais);
    formData.append("telCaract",usuarioPanel.telCaract.trim());
    formData.append("telNumero",usuarioPanel.telNumero.trim());
    formData.append("perfil",usuarioPanel.perfil.trim());
    formData.append("password",usuarioPanel.password);
    formData.append("nameImage",nameImage.trim());
    return this.http.post(this.url+"crear",formData,{headers:headers});
  }


  //
  borrar(form:UsuarioPanel):Observable<any>{
    return this.http.delete(this.url+"borrar/"+form.id);
  }


  //
  mostrar(id):Observable<any>{
    return this.http.get(this.url+"mostrar/"+id);
  }


  //
  editar(nameImage:string,usuarioPanel:UsuarioPanel,file:File):Observable<any>{
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    if(file){
      formData.append("image", file); 
    } 
    formData.append("id",usuarioPanel.id.toString());
    formData.append("password",usuarioPanel.password);
    formData.append("nombreCompleto",usuarioPanel.nombreCompleto.trim());
    formData.append("nombreUsuario",usuarioPanel.nombreUsuario.trim());
    formData.append("email",usuarioPanel.email.trim());
    formData.append("telPais",usuarioPanel.telPais);
    formData.append("telCaract",usuarioPanel.telCaract.trim());
    formData.append("telNumero",usuarioPanel.telNumero.trim());
    formData.append("perfil",usuarioPanel.perfil.trim());
    formData.append("nameImage",nameImage.trim());
    return this.http.post(this.url+"editar",formData,{headers:headers});
  }


  //
  editarPerfi(nameImage:string,usuarioPanel:UsuarioPanel,file:any):Observable<any> {
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append("image", file);
    formData.append("id",usuarioPanel.id.toString());
    formData.append("password",usuarioPanel.password);
    formData.append("nombreCompleto",usuarioPanel.nombreCompleto.trim());
    formData.append("email",usuarioPanel.email.trim());
    formData.append("password",usuarioPanel.password.trim());
    formData.append("telPais",usuarioPanel.telPais);
    formData.append("telCaract",usuarioPanel.telCaract.trim());
    formData.append("telNumero",usuarioPanel.telNumero.trim());
    formData.append("nameImage",nameImage.trim());
    return this.http.post(this.url+"editar-perfil",formData,{headers:headers});
  }


  //
  cambiarClave(id:number){
    let formData = new FormData();
    return this.http.post(this.url+"cambiar-clave",formData);
  }


  //
  login(usuarioPanel:UsuarioPanel){
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const formData = new FormData(); 
    let key = encHex.parse("0123456789abcdef0123456789abcdef");
    let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
    let encrypted = aes.encrypt(usuarioPanel.password, key, {iv:iv, padding:padZeroPadding}).toString();
    formData.append("password",encrypted);
    formData.append("nombreUsuario" , usuarioPanel.nombreUsuario);
    return this.http.post(this.url+ "login", formData,{headers:headers});
  }


  //
  recuperarPassword(email:string){
    return this.http.post(this.url+"recuperar-contraseña",email);
  }


  //
  decriptar(usuarioPanel:UsuarioPanel){
    let formData = new FormData();
    // message to encrypt
    let msg = "Hello world";

    // the key and iv should be 32 hex digits each, any hex digits you want, but it needs to be 32 on length each
    let key = encHex.parse("0123456789abcdef0123456789abcdef");
    let iv =  encHex.parse("abcdef9876543210abcdef9876543210");

    // encrypt the message
    let encrypted = aes.encrypt(usuarioPanel.password, key, {iv:iv, padding:padZeroPadding}).toString();
    
    formData.append("password",encrypted);
    formData.append("nombreUsuario",usuarioPanel.nombreUsuario);
    return this.http.post(this.url+"decriptar",formData);
  }


  // Levanta el token del localStorage
  getToken(){
    return localStorage.getItem('auth_token');
  }


  // Chequea que el token sea valido
  isValidToken(){
    return new Promise((resolve,reject) => {
      const token = this.getToken();
      if(token){
        const payload = this.payload(token);
        if(payload){
          this.http.get(this.url+"auth-status").subscribe(
            (value) => {
              if(value === "credenciales validas"){
                resolve(this.url + 'login'  === payload.iss ? true : false);
              }
              if(value === "credenciales invalidas"){    
                resolve(false);
              }
            },
            (error) => {
              resolve(false);
            }
          );
        }
      } 
      else {
        resolve(false)
      }
    })
  }


  // Hace el parseo del jwt 
  payload(token) {
    const jwtPayload = token.split('.')[1];
    return JSON.parse(atob(jwtPayload));
  }


  // Chequea que el usuario este logueado
  isLoggedIn() {
    return this.isValidToken();
  }


  //setea el token en el local storage
  handleData(token){
    localStorage.setItem('auth_token', JSON.stringify(token));
  }


  //retorna el usuario logueado
  profileUser(): Observable<any> {
    return this.http.get(this.url + 'user-profile');
  }


  //chequea que usuario logueado tenga roll administrador
  adminCheck(): Observable<any> {
    return this.http.get(this.url + 'is-admin');
  }


  //
  getImagenRol(){
    this.profileUser().subscribe(val=>{
      let object = val;
      this.imagen = object.imagen;
      this.rol = object.perfil;
    })
  }


  //
  getImagen(){
    return this.imagen;
  }


  //
  getPerfil(){
    return localStorage.getItem('usuario_perfil');
  }


  //
  getImagenRolafterEdit(){
    this.getImagenRol()
  }


  //
  setRolUsuario(usuario){
    localStorage.setItem('usuario_perfil', usuario.perfil);
  }


  //
  datosAutorizacion(): Observable<any> {
    return this.http.get(this.url + 'datos-autorizacion');
  }


  //
  setDatosAutorizacion(data){
    localStorage.setItem('turno_habilitado', data.turnoHabilitado);
    localStorage.setItem('usuario_perfil', data.usuarioPanel.perfil);
  }


  //
  deleteDatosAutorizacion(){
    localStorage.removeItem('turno_habilitado');
    localStorage.removeItem('auth_token');
    localStorage.removeItem('usuario_perfil');
  }


  //
  logout(){
    localStorage.removeItem('turno_habilitado');
    localStorage.removeItem('auth_token');
    localStorage.removeItem('usuario_perfil');
    this.router.navigate(['/auth/login-panel']);
  }
}
