<div class="headerPanel mat-elevation-z4">
    <img src="assets/img/ico_campana.png" class="campana"/>
    <img [src]="imagen" class="icon" [matMenuTriggerFor]="appMenu"/>
    <mat-menu class="dropdown" #appMenu="matMenu">
        <mat-nav-list>
            <a mat-list-item class="buttonDash" [routerLink]="['/seccion-panel/editar-perfil']">Editar Perfil</a>
            <a mat-list-item class="buttonDash" (click)="logout()">Salir</a>
        </mat-nav-list>
    </mat-menu>
</div>
