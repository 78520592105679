<div class="panel">
    <div class="dashboard">
        <div class="dashboardContent mat-elevation-z4">
            <app-dashboard [usuarioPanel]="usuarioPanel"></app-dashboard>
        </div>
    </div>
    <div class="panelContent">
        <app-header-panel [usuarioPanel]="usuarioPanel"></app-header-panel>
        <app-form-sucursal [response]="response" [editar]="editar"></app-form-sucursal>
    </div>
</div>


