import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePanelComponent } from './home-panel/home-panel.component';
import { HomePanelRoutingModule } from './home-panel-routing.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { SharedPanelModule } from '../shared-panel/shared-panel.module'; 
import { HttpClientModule } from '@angular/common/http';
import { PlanPanelComponent } from './plan-panel/plan-panel.component';
import { IndicadoresPanelComponent } from './indicadores-panel/indicadores-panel.component';
import { MaterialModule } from 'app/material/material.module';


@NgModule({
  declarations: [
    HomePanelComponent,
    PlanPanelComponent,
    IndicadoresPanelComponent
  ],
  imports: [
    CommonModule,
    HomePanelRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPanelModule,
    HttpClientModule,
    MaterialModule
  ]
})
export class HomePanelModule { }
