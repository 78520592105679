import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';


@Component({
  selector: 'app-plan-panel',
  templateUrl: './plan-panel.component.html',
  styleUrls: ['./plan-panel.component.scss']
})
export class PlanPanelComponent implements OnInit {


  //
  response:any;
  plan:any;
  @Input() set setPlan(value){
    this.setPlanForm(value);
  }
  planForm = new FormGroup({
    nombrePlan : new FormControl(''),
    cantidadUsuario : new FormControl(''),
    espacioMaximo : new FormControl(''),
    cantidadAgendas : new FormControl(''),
    landing : new FormControl(''),
  });


  //
  constructor() {}


  //
  ngOnInit(): void {}


  //
  setPlanForm(value){
    if(value != undefined || value != null){
      this.plan = value;
      this.planForm.setValue({
        nombrePlan: this.plan.nombrePlan,
        cantidadUsuario: this.plan.cantidadUsuario,
        espacioMaximo: this.plan.espacioMaximo,
        cantidadAgendas: this.plan.cantidadAgendas, 
        landing: (this.plan.landing == 1) ? 'activado' : 'desactivado',
      });
    }
  }

}
