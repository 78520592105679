<div class="panel">
    <div class="dashboard">
        <app-dashboard [usuarioPanel]="usuarioPanel"></app-dashboard>  
    </div>
    <div class="panelContent">
        <app-header-panel [usuarioPanel]="usuarioPanel"></app-header-panel>
        <app-tipo-documento-form [editar]="editar" [tipoDocumento]="tipoDocumento"></app-tipo-documento-form>
    </div>
</div>

