import { IsAdminGuard } from './guards/is-admin.guard';
import { PacienteGuard } from './guards/paciente.guard';
import { TurnosPanelGuard } from './guards/turnos-panel.guard'; 
import { TurnosMedicoGuard } from './guards/turnos-medico.guard'; 
import { TurnosPacienteGuard } from './guards/turnos-paciente.guard'; 
import { UsuarioPanelGuard } from './guards/usuario-panel.guard';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MedicoGuard } from './guards/medico.guard';

const routes: Routes = [
  {
    path: '', redirectTo: 'inicio', pathMatch: 'full'
  },
  {
    path: "inicio",
    loadChildren: () => import("./inicio/inicio.module").then((m) => m.InicioModule),
  },
  {
    path: "",
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
  },
  {
    path: "doctor",
    loadChildren: () => import("./doctor/doctor.module").then((m) => m.DoctorModule),
  },
  {
    path: "",
    loadChildren: () => import("./confirmar-turnos/confirmar-turnos.module").then((m) => m.ConfirmarTurnosModule),
  },
  {
    path: "",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/shared-panel/shared-panel.module").then((m) => m.SharedPanelModule),
    canActivate: [UsuarioPanelGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/informes/informes.module").then((m) => m.InformesModule),
    canActivate: [UsuarioPanelGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/estudio-medico/estudio-medico.module").then((m) => m.EstudioMedicoModule),
    canActivate: [UsuarioPanelGuard, IsAdminGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/sucursal/sucursal.module").then((m) => m.SucursalModule),
    canActivate: [UsuarioPanelGuard, IsAdminGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/obra-social/obra-social.module").then((m) => m.ObraSocialModule),
    canActivate: [UsuarioPanelGuard, IsAdminGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/tipo-documento/tipo-documento.module").then((m) => m.TipoDocumentoModule),
    canActivate: [UsuarioPanelGuard, IsAdminGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/usuario-panel/usuario-panel.module").then((m) => m.UsuarioPanelModule),
    canActivate: [UsuarioPanelGuard, IsAdminGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/medico/medico.module").then((m) => m.MedicoModule),
    canActivate: [UsuarioPanelGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/paciente/paciente.module").then((m) => m.PacienteModule),
    canActivate: [UsuarioPanelGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/centro-medico/centro-medico.module").then((m) => m.CentroMedicoModule),
    canActivate: [UsuarioPanelGuard, IsAdminGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/home-panel/home-panel.module").then((m) => m.HomePanelModule),
    canActivate: [UsuarioPanelGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/usuario-panel-perfil/usuario-panel-perfil.module").then((m) => m.UsuarioPanelPerfilModule),
    canActivate: [UsuarioPanelGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/busqueda-estudios-medicos/busqueda-estudios-medicos.module").then((m) => m.BusquedaEstudiosMedicosModule),
    canActivate: [UsuarioPanelGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/detalle-estudios-medicos/detalle-estudios-medicos.module").then((m) => m.DetalleEstudiosMedicosModule),
    canActivate: [UsuarioPanelGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/estudios-medicos-paciente/estudios-medicos-paciente.module").then((m) => m.EstudiosMedicosPacienteModule),
    canActivate: [UsuarioPanelGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/especialidad/especialidad.module").then((m) => m.EspecialidadModule),
    canActivate: [UsuarioPanelGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/turnos/turnos.module").then((m) => m.TurnosModule),
    canActivate: [UsuarioPanelGuard, IsAdminGuard, TurnosPanelGuard]
  },
  {
    path: "",
    loadChildren: () => import("./panel-seccion/general/general.module").then((m) => m.GeneralModule),
    canActivate: [UsuarioPanelGuard, IsAdminGuard]
  },
  {
    path: "",
    loadChildren: () => import("./paciente-seccion/home-paciente/home-paciente.module").then((m) => m.HomePacienteModule),
    canActivate: [PacienteGuard]
  },
  {
    path: "",
    loadChildren: () => import("./paciente-seccion/turnos-paciente/turnos-paciente.module").then((m) => m.TurnosPacienteModule),
    canActivate: [PacienteGuard]
  },
  {
    path: "",
    loadChildren: () => import("./paciente-seccion/detalle-estudios-medicos/detalle-estudios-medicos.module").then((m) => m.DetalleEstudiosMedicosModule),
    canActivate: [PacienteGuard]
  },
  {
    path: "",
    loadChildren: () => import("./paciente-seccion/editar-perfil-paciente/editar-perfil-paciente.module").then((m) => m.EditarPerfilPacienteModule),
    canActivate: [PacienteGuard]
  },
  {
    path: "",
    loadChildren: () => import("./medico-seccion/shared-medico/shared-medico.module").then((m) => m.SharedMedicoModule),
    canActivate: [MedicoGuard]
  },
  {
    path: "",
    loadChildren: () => import("./medico-seccion/turnos-medico/turnos-medico.module").then((m) => m.TurnosMedicoModule),
    canActivate: [MedicoGuard, TurnosMedicoGuard]
  },
  {
    path: "",
    loadChildren: () => import("./medico-seccion/home-medico/home-medico.module").then((m) => m.HomeMedicoModule),
    canActivate: [MedicoGuard]
  },
  {
    path: "",
    loadChildren: () => import("./medico-seccion/editar-perfil-medico/editar-perfil-medico.module").then((m) => m.EditarPerfilMedicoModule),
    canActivate: [MedicoGuard]
  },
  {
    path: "",
    loadChildren: () => import("./medico-seccion/listar-estudios-medicos/listar-estudios-medicos.module").then((m) => m.ListarEstudiosMedicosModule),
    canActivate: [MedicoGuard]
  },
  {
    path: "",
    loadChildren: () => import("./medico-seccion/detalle-estudios-medicos/detalle-estudios-medicos.module").then((m) => m.DetalleEstudiosMedicosModule),
    canActivate: [MedicoGuard]
  },
  { path: "**", redirectTo: "inicio", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
