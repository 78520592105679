import { ElementRef,AfterViewInit,Component, OnInit,ViewChild,NgModule  } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { ConfirmDialogComponent } from 'app/shared/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CentroMedicoService } from 'app/services/centro-medico.service';
import { Sucursal } from 'app/models/sucursal.interface';
import { MatPaginator } from '@angular/material/paginator';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { CentroMedico } from 'app/models/centroMedico.interface';
import { SpinnerService } from 'app/services/spinner.service';
import { TelefonoPaisService } from 'app/services/telefono-pais.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-listar-sucursal',
  templateUrl: './listar-sucursal.component.html',
  styleUrls: ['./listar-sucursal.component.scss']
})
export class ListarSucursalComponent implements OnInit {


  //
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('buscador') buscador: ElementRef;
  displayedColumns: string[] = ['nombre','direccion','telefono','email','acciones'];
  editar:boolean = false;
  data: any[];
  dataSource:any;
  usuarioPanel:any;
  searchKey:string;
  listaTelefonos:any;
  listaSucursales:any;
  response:any;


  //
  constructor(
    public dialog: MatDialog,
    private sucursalService:CentroMedicoService,
    private spinnerService:SpinnerService,
    private usuarioPanelService:UsuarioPanelService,
    private telefonoPaisService:TelefonoPaisService,
  ) { 
    this.spinnerService.mostrarSpinner();
    this.usuarioPanelService.profileUser().pipe(
      map(data => {
        this.usuarioPanel = data;
      }),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        this.usuarioPanelService.logout();
        return of({ results: null });
      }),
      switchMap(data => this.sucursalService.listarSucursales().pipe(
        map(data => { 
          this.response = data;
          this.listar(this.response.listaCentroMedico);
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return of({ results: null });
      }),
    )
    .subscribe(
      (data) => {
        console.log('data',data);
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
      }
    );
  }


  //
  ngOnInit(): void {}


  //
  listar(data){
    this.data = data;
    this.data.forEach((item)=>{
      item.telefono = item.telPais+"-"+item.telCaract+"-"+item.telNumero;
    });
    this.dataSource = new MatTableDataSource<Sucursal>(this.data);
    this.dataSource.paginator = this.paginator;
  }

  
  //
  openDialog(centroMedico:CentroMedico){
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      width: '50%',
      data: 'sucursal'
    })
    dialogRef.afterClosed().subscribe(res=>{
      if(res){
        this.borrar(centroMedico);
      }
    })
  }


  //
  borrar(sucursal:CentroMedico){
    this.spinnerService.mostrarSpinner();
    this.sucursalService.borrar(sucursal).pipe(
      map(data => {}),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return error;
      }),
      switchMap(data => this.sucursalService.listarSucursales().pipe(
        map(data => { 
          let response:any = data; 
          this.listar(response.listaCentroMedico);
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return error;
      }),
    )
    .subscribe(
      (data) => console.log('data',data),
      (error) => console.error('Error al llamar a la API:', error)
    );
  }


  //
  filter(event){
    let dataFilter:Sucursal[] = [];
    this.data.forEach(obj=>{
      let valorString:string = this.buscador.nativeElement.value;
      if(obj.nombre.toUpperCase().includes(valorString.toUpperCase())){
        dataFilter.push(obj);
      } 
      else if(obj.direccion.toUpperCase().includes(valorString.toUpperCase())){
        dataFilter.push(obj);
      }
      else if(obj.telefono.toUpperCase().includes(valorString.toUpperCase())){
        dataFilter.push(obj);
      }
      else if(obj.email.toUpperCase().includes(valorString.toUpperCase())){
        dataFilter.push(obj);
      }
    })

    this.dataSource = new MatTableDataSource<Sucursal>(dataFilter);
    this.dataSource.paginator = this.paginator;
  }


  //
  listaActualizada(mensaje:boolean){
    this.sucursalService.listarSucursales().subscribe(
      data => {
        let response:any = data;
        this.listar(response.listaCentroMedico);
        this.spinnerService.ocultarSpinner();
      },
      error => {
        this.spinnerService.ocultarSpinner();
      }
    )
  }

  
}


