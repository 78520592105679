import { Component, OnInit } from '@angular/core';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { SpinnerService } from 'app/services/spinner.service';
import { Router,ActivatedRoute } from '@angular/router';
import { TipoDocumentoService } from 'app/services/tipo-documento.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-tipo-documento-editar',
  templateUrl: './tipo-documento-editar.component.html',
  styleUrls: ['./tipo-documento-editar.component.scss']
})
export class TipoDocumentoEditarComponent implements OnInit {


  //
  usuarioPanel:any;
  tipoDocumento:any;
  editar:boolean = true;


  //
  constructor(
    private tipoDocumentoService:TipoDocumentoService,
    private actRoute:ActivatedRoute,
    private router:Router,
    private usuarioPanelService:UsuarioPanelService,
    private spinnerService:SpinnerService,
  ) {
    this.spinnerService.mostrarSpinner();
    let id = this.actRoute.snapshot.paramMap.get("id");
    this.usuarioPanelService.profileUser().pipe(
      map(data => {
        console.log("data1: ", data);
        this.usuarioPanel = data;
      }),
      catchError(error => {
        console.log("Error from 1 call: ", error);
        this.spinnerService.ocultarSpinner();
        this.usuarioPanelService.logout();
        return of({ results: null });
      }),
      switchMap(data => this.tipoDocumentoService.mostrar(id).pipe(
        map(data => { 
          console.log("data2: ", data);
          this.tipoDocumento = data;
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        console.log("Error from 2 call: ", error);
        this.spinnerService.ocultarSpinner();
        return of({ results: null });
      }),
    )
    .subscribe(
      (data) => console.log('data',data),
      (error) => console.error('Error al llamar a la API:', error)
    );
  }


  //
  ngOnInit(): void {}


}
