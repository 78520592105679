<div class="panel">
    <div class="dashboard">
        <div class="dashboardContent mat-elevation-z4">
            <app-dashboard [usuarioPanel]="usuarioPanel"></app-dashboard>
        </div>
    </div>
    <div class="panelContent">
        <app-header-panel [usuarioPanel]="usuarioPanel"></app-header-panel>
        <app-crear-form-medico [response]="response" [editar]="editar" (listar)="listaActualizada($event)"></app-crear-form-medico>
        <div class="seccionTable">
            <mat-card class="cardTable mat-elevation-z4">
                <div class="topTitle"><mat-card class="titleSeccion">Médicos</mat-card></div>
                <mat-form-field class="example-full-width buscador" appearance="fill">
                    <mat-label>Buscador</mat-label>
                    <input placeholder="" #buscador (keyup)="filter($event)" [(ngModel)]="searchKey" type="text" matInput autocomplete="off">
                </mat-form-field>
                <table  class="" mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="apellido">
                        <th mat-header-cell *matHeaderCellDef class="th"><span class="span">Apellido</span> </th>
                        <td mat-cell *matCellDef="let element" class="td"> {{element.nombreCompleto}} </td>
                    </ng-container>
                    <ng-container matColumnDef="tipo">
                        <th mat-header-cell *matHeaderCellDef class="th">Tipo Doc.</th>
                        <td mat-cell *matCellDef="let element" class="td">{{element.texto}} </td>
                    </ng-container>
                    <ng-container matColumnDef="numeroDoc">
                        <th mat-header-cell *matHeaderCellDef class="th">Núm. Doc.</th>
                        <td mat-cell *matCellDef="let element" class="td"> {{element.numeroDocumento}}</td>
                    </ng-container>
                    <ng-container matColumnDef="matricula">
                        <th mat-header-cell *matHeaderCellDef class="th">Matrícula </th>
                        <td mat-cell *matCellDef="let element" class="td">{{element.matricula}}</td>
                    </ng-container>
                    <ng-container matColumnDef="acciones">
                        <th mat-header-cell *matHeaderCellDef class="th">Acciones</th>
                        <td mat-cell *matCellDef="let element" class="td">
                            <div class="divIcons">
                                <div class="editar divIcon" [routerLink]="['/seccion-panel/medico/editar',element.id]">
                                    <mat-icon class="icon">edit</mat-icon>
                                </div>
                                <a class="whatsapp divIcon" target="_blank" href="https://api.whatsapp.com/send?phone={{element.celular}}">
                                    <i class="icon wsp fa-brands fa-whatsapp"></i>
                                </a>
                                <div class="key divIcon" (click)="cambiarPassword(element.id)">
                                    <mat-icon class="icon">vpn_key</mat-icon>
                                </div>
                                <div class="borrar divIcon" (click)="openDialog(element)">
                                    <mat-icon class="icon">delete_forever</mat-icon>
                                </div>
                            </div>
                        </td>
                    </ng-container> 
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>    
                <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons 
                 aria-label="Select page of periodic elements">
                </mat-paginator>
            </mat-card>        
        </div>
    </div>
</div>

