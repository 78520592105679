<div class="content-login">
    <mat-card class="example-card mat-elevation-z4">
        <div class="divImg">
            <img src="assets/img/logo.png" class="img"/>
        </div>

        <form class="example-form" [formGroup]="loginForm" (submit)="login(loginForm.value)">
            <div class="titleLogIn"><span>Ingresar - Panel</span></div>
            <div class="divInput">
                <mat-form-field class="example-full-width" appearance="fill" class="input">
                    <mat-label>Usuario</mat-label>
                    <input type="email" formControlName="nombreUsuario" matInput placeholder="">
                </mat-form-field>
            </div>
            <div class="divInput">
                <mat-form-field class="example-full-width" appearance="fill" class="input">
                    <mat-label>Password</mat-label>
                    <input type="password" formControlName="password" matInput placeholder="">
                </mat-form-field>
                <div class="recuperar">
                    <a class="recuperarEnlace" [routerLink]="['/auth/recuperar-contraseña-panel']">Recuperar Password</a>
                </div>
            </div>
            <!-- recuperar-contraseña -->
            <div class="divButton">
                <button type="submit" mat-raised-button color="primary" class="ingresar">Ingresar</button>
            </div>
        </form>
    </mat-card>
</div>
