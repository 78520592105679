import { ElementRef,AfterViewInit,Component, OnInit,ViewChild,NgModule ,Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared/confirm-dialog/confirm-dialog.component';
import { FotoDialogComponent } from 'app/shared/foto-dialog/foto-dialog.component';
import { SpinnerService } from 'app/services/spinner.service';
import { TelefonoPaisService } from 'app/services/telefono-pais.service';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { Router,ActivatedRoute } from '@angular/router';
import { UsuarioPanel } from 'app/models/usuarioPanel.interface';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-usuario-panel-editar',
  templateUrl: './usuario-panel-editar.component.html',
  styleUrls: ['./usuario-panel-editar.component.scss']
})
export class EditarUsuarioPanelComponent implements OnInit {


  //
  usuarioPanelFormulario:any;
  editar:boolean = true;
  usuarioPanel:any;
  telefonoPaisApi:any;
  response:any;


  //
  constructor(
    private usuarioPanelService:UsuarioPanelService,
    private spinnerService:SpinnerService,
    private telefonoPaisService:TelefonoPaisService,
    public fotoDialog: MatDialog,
    private actRoute:ActivatedRoute,
    private router:Router,
  ) { 
    this.spinnerService.mostrarSpinner();
    let id = this.actRoute.snapshot.paramMap.get( "id" );
    this.usuarioPanelService.profileUser().pipe(
      map(data => {
        this.usuarioPanel = data;
      }),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        this.usuarioPanelService.logout();
        return of({ results: null });
      }),
      switchMap(data => this.usuarioPanelService.mostrar(id).pipe(
        map(data => { 
          this.response = data;
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return of({ results: null });
      }),
    )
    .subscribe(
      (data) => {
        console.log('data',data);
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
      }
    );
  }


  //
  ngOnInit(): void {}


}
