import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { ElementRef , Component , OnInit , ViewChild, Input } from '@angular/core';
import { UsuarioPanel } from 'app/models/usuarioPanel.interface';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { GlobalVariable } from 'app/global/global';

@Component({
  selector: 'app-header-panel',
  templateUrl: './header-panel.component.html',
  styleUrls: ['./header-panel.component.scss']
})
export class HeaderPanelComponent implements OnInit {


  //
  usuarioPanelValue:any;
  imagen:string;
  imagenAux:string;
  @Input() set usuarioPanel(value: any) {
    if(value) {
      this.usuarioPanelValue = value;
      this.imagen = GlobalVariable.BASE_API_URL+'storage/images/usuario-panel/'+this.usuarioPanelValue.imagen; 
    }
  }


  //
  constructor(
    private usuarioPanelService:UsuarioPanelService,
    private router:Router,
  ) {}



  //
  ngOnInit(): void {} 


  //
  setImagen(){
    this.imagen = GlobalVariable.BASE_API_URL+'storage/images/usuario-panel/'+this.usuarioPanelValue.imagen;  
  }


  //
  logout(){
    this.usuarioPanelService.logout();
  }

}
