import { Component, OnInit, Output, EventEmitter  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { InformesService } from 'app/services/informes.service';
import { SpinnerService } from 'app/services/spinner.service';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-informes',
  templateUrl: './informes.component.html',
  styleUrls: ['./informes.component.scss']
})
export class InformesComponent implements OnInit {


  //
  informes:any;
  estudiosMedicosPorcentaje:any;
  turnosPorcentaje:any;
  pacientesPorAnio:any;
  estudiosMedicosPacientesPorAnio:any;
  turnosPorAnio:any;
  topMedicos:any;
  cantidad:any;
  usuarioPanel:any;
  tituloPacientesPorAnio="Pacientes";
  descPacientesPorAnio="Informe cantidad de pacientes creados en el último año por mes.";
  tituloEstudiosMedicosPacientesPorAnio="Estudios médico de pacientes";
  descEstudiosMedicosPacientesPorAnio="Informe cantidad de estudios médico de pacientes creados en el último año por mes.";
  tituloTurnosPorAnio="Turnos";
  descPacientePorAnio="Informe cantidad de turnos creados en el último año por mes.";
  tituloEstudiosMedicosTorta="Estudios médicos";
  descEstudiosMedicosTorta="Informe porcentaje de estudios médicos por cada tipo.";
  tituloTurnosEspecialidadTorta="Turnos";
  descTurnosEspecialidadTorta="Informe porcentaje de turnos relacionado a especialidades de médicos.";
  tituloTopMedicos="Médicos";
  descTopMedicos="Informe de top 5 médicos con más pacientes.";


  //
  constructor(
    private usuarioPanelService:UsuarioPanelService,
    private informesService:InformesService,
    private spinnerService:SpinnerService
  ) {
    this.spinnerService.mostrarSpinner();
    this.usuarioPanelService.profileUser().pipe(
      map(data => {
        this.usuarioPanel = data;
      }),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        this.usuarioPanelService.logout();
        return of({ results: null });
      }),
      switchMap(data => this.informesService.mostrar().pipe(
        map(data => { 
          this.informes = data;
          this.topMedicos = this.informes.topMedicos;
          this.pacientesPorAnio = this.informes.pacientesPorAnio;
          this.estudiosMedicosPacientesPorAnio = this.informes.estudiosMedicosPacientesPorAnio;
          this.turnosPorAnio = this.informes.turnosPorAnio;
          this.estudiosMedicosPorcentaje = this.informes.estudiosMedicosPorcentaje;
          this.turnosPorcentaje = this.informes.turnosPorcentaje;
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return of({ results: null });
      }),
    )
    .subscribe(
      (data) => {
        console.log('data',data);
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
      }
    );
  }


  //
  ngOnInit(): void {}

  
}

