import { ElementRef,AfterViewInit,Component, OnInit,ViewChild,NgModule ,Inject } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FotoDialogComponent } from 'app/shared/foto-dialog/foto-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { SpinnerService } from 'app/services/spinner.service';
import { PacienteService } from 'app/services/paciente.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-editar-paciente',
  templateUrl: './editar-paciente.component.html',
  styleUrls: ['./editar-paciente.component.scss']
})
export class EditarPacienteComponent implements OnInit {


  //
  editar:boolean = true;
  usuarioPanel:any;
  pacienteFormulario:any;
  response:any;


  //
  constructor(
    public fotoDialog: MatDialog,
    private usuarioPanelService:UsuarioPanelService,
    private spinnerService:SpinnerService,
    private pacienteService:PacienteService,
    private actRoute:ActivatedRoute,
    private router:Router,
  ) { 
    this.spinnerService.mostrarSpinner();
    let id = this.actRoute.snapshot.paramMap.get("id");
    this.usuarioPanelService.profileUser().pipe(
      map(data => {
        this.usuarioPanel = data;
      }),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        this.usuarioPanelService.logout();
        return of({ results: null });
      }),
      switchMap(data => this.pacienteService.mostrarformEditarPaciente(parseInt(id)).pipe(
        map(data => { 
          this.response = data;
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return of({ results: null });
      }),
    )
    .subscribe(
      (data) => {
        console.log('data',data);
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
      }
    );
  }

  ngOnInit(): void {
  }

}
