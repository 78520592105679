import { ElementRef, Component, OnInit, Input, ViewChild, Output } from '@angular/core';
import { GlobalVariable } from 'app/global/global';
import { CentroMedicoService } from 'app/services/centro-medico.service';
import { PacienteService } from 'app/services/paciente.service';
import { Router,ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ContactoDialogComponent } from 'app/shared/contacto-dialog/contacto-dialog.component';
import { Paciente } from 'app/models/paciente.interface';
import { environment } from 'environments/environment.prod';
import { MedicoService } from 'app/services/medico.service';
import { EventEmitter } from '@angular/core';


@Component({
  selector: 'app-header-medico',
  templateUrl: './header-medico.component.html',
  styleUrls: ['./header-medico.component.scss']
})
export class HeaderMedicoComponent implements OnInit {


  //
  @ViewChild('dropdown') dropdown: ElementRef;
  imagen:string;
  menu:string = 'true';
  medico:any;
  @Output() messageEvent = new EventEmitter<string>();
  @Input() set response(value:any) {
    if(value) {
      console.log('medico 1',value);
      this.iniciarMedico(value);
    }
  }


  //
  constructor(
    private pacienteService:PacienteService,
    private medicoService:MedicoService,
    private router:Router,
  ) { }


  //
  mobileOpenClose() {}


  //
  ngOnInit(): void {}


  //
  iniciarMedico(data){
    this.medico = data.medico;
    this.imagen = `${GlobalVariable.BASE_API_URL}storage/images/medico/${this.medico.imagen}`;
  }


  //
  botonDesplegableShadow(){
    this.menu = (this.menu == 'true') ? 'false' : 'true';
    this.messageEvent.emit(this.menu);
  }


  //
  logout(){
    this.medicoService.logout();
  }
}
