import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Medico } from 'app/models/medico.interface';
import { GlobalVariable } from 'app/global/global';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import padZeroPadding from 'crypto-js/pad-zeropadding';
import { environment } from 'environments/environment.prod';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MedicoService {


  //
  url:string = GlobalVariable.BASE_API_URL+"api/medico/";
  titulo_login:string;
  

  //
  constructor(
    private http:HttpClient,
    private router:Router,
  ) {
    this.titulo_login = environment.MEDICO_LOGIN;
  }


  //
  listar():Observable<any>{
    return this.http.get(this.url+"listar");
  }


  //
  formularioMedico(){
    return this.http.get(this.url+"fomulario-medico");
  }


  //
  crear(nameImage:string,medico:any,file:File){
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    if(file){
      formData.append("image", file);
    }
    formData.append("nombreCompleto",medico.nombreCompleto.trim());
    formData.append("tipoDocumento",medico.tipoDocumento);
    formData.append("numeroDocumento",medico.numeroDocumento.trim());
    formData.append("especialidad",medico.especialidad);
    formData.append("matricula",medico.matricula.trim());
    formData.append("email",medico.email.trim());
    formData.append("telPais",medico.telPais);
    formData.append("telCaract",medico.telCaract.trim());
    formData.append("telNumero",medico.telNumero.trim());
    formData.append("celPais",medico.celPais);
    formData.append("celCaract",medico.celCaract.trim());
    formData.append("celNumero",medico.celNumero.trim());
    formData.append("password",medico.password);
    formData.append("codigoInterno",medico.codigoInterno.trim());
    formData.append("nameImage",nameImage.trim());
    formData.append("agenda",medico.agenda.toString());
    formData.append("color_agenda",medico.color_agenda.trim());
    formData.append("notificaciones",medico.notificaciones.toString());
    if(medico.agenda){
      formData.append("lunesDesde",medico.lunesDesde.trim());
      formData.append("lunesHasta",medico.lunesHasta.trim());
      formData.append("martesDesde",medico.martesDesde.trim());
      formData.append("martesHasta",medico.martesHasta.trim());
      formData.append("miercolesDesde",medico.miercolesDesde.trim());
      formData.append("miercolesHasta",medico.miercolesHasta.trim());
      formData.append("juevesDesde",medico.juevesDesde.trim());
      formData.append("juevesHasta",medico.juevesHasta.trim());
      formData.append("viernesDesde",medico.viernesDesde.trim());
      formData.append("viernesHasta",medico.viernesHasta.trim());
      formData.append("sabadoDesde",medico.sabadoDesde.trim());
      formData.append("sabadoHasta",medico.sabadoHasta.trim());
      formData.append("domingoDesde",medico.domingoDesde.trim());
      formData.append("domingoHasta",medico.domingoHasta.trim());
    }

    return this.http.post(this.url+"crear",formData,{headers:headers});
  }


  //
  borrar(form:Medico):Observable<any>{
    return this.http.delete(this.url+"borrar/"+form.id);
  }


  //
  mostrar(id):Observable<any>{
    return this.http.get(this.url+"mostrar/"+id);
  }


  //
  editar(nameImage:string,medico:any,file:any):Observable<any>{
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    console.log('medico',medico);
    if(file){
      formData.append("image", file);
    }
    formData.append("id",medico.id.toString());
    formData.append("nombreCompleto",medico.nombreCompleto.trim());
    formData.append("tipoDocumento",medico.tipoDocumento);
    formData.append("numeroDocumento",medico.numeroDocumento.trim());
    formData.append("especialidad",medico.especialidad);
    formData.append("matricula",medico.matricula.trim());
    formData.append("email",medico.email.trim());
    formData.append("telPais",medico.telPais);
    formData.append("telCaract",medico.telCaract.trim());
    formData.append("telNumero",medico.telNumero.trim());
    formData.append("celPais",medico.celPais);
    formData.append("celCaract",medico.celCaract.trim());
    formData.append("celNumero",medico.celNumero.trim());
    formData.append("password",medico.password);
    formData.append("nameImage",nameImage.trim());
    formData.append("codigoInterno",medico.codigoInterno.trim());
    formData.append("agenda",medico.agenda.toString());
    formData.append("color_agenda",medico.color_agenda.trim());
    formData.append("notificaciones",medico.notificaciones.toString());
    if(medico.agenda){
      formData.append("lunesDesde",medico.lunesDesde.trim());
      formData.append("lunesHasta",medico.lunesHasta.trim());
      formData.append("martesDesde",medico.martesDesde.trim());
      formData.append("martesHasta",medico.martesHasta.trim());
      formData.append("miercolesDesde",medico.miercolesDesde.trim());
      formData.append("miercolesHasta",medico.miercolesHasta.trim());
      formData.append("juevesDesde",medico.juevesDesde.trim());
      formData.append("juevesHasta",medico.juevesHasta.trim());
      formData.append("viernesDesde",medico.viernesDesde.trim());
      formData.append("viernesHasta",medico.viernesHasta.trim());
      formData.append("sabadoDesde",medico.sabadoDesde.trim());
      formData.append("sabadoHasta",medico.sabadoHasta.trim());
      formData.append("domingoDesde",medico.domingoDesde.trim());
      formData.append("domingoHasta",medico.domingoHasta.trim());
    }
    return this.http.post(this.url+"editar",formData,{headers:headers});
  }


  //
  editarPerfil(nameImage:string,medico:any,file:File){
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    if(file != null){
      formData.append("image",file);
    }
    formData.append("id",medico.id.toString());
    formData.append("nombreCompleto",medico.nombreCompleto.trim());
    formData.append("tipoDocumento",medico.tipoDocumento);
    formData.append("numeroDocumento",medico.numeroDocumento.trim());
    formData.append("especialidad",medico.especialidad);
    formData.append("matricula",medico.matricula.trim());
    formData.append("email",medico.email.trim());
    formData.append("telPais",medico.telPais);
    formData.append("telCaract",medico.telCaract.trim());
    formData.append("telNumero",medico.telNumero.trim());
    formData.append("celPais",medico.celPais);
    formData.append("celCaract",medico.celCaract.trim());
    formData.append("celNumero",medico.celNumero.trim());
    formData.append("password",medico.password);
    formData.append("codigoInterno",medico.codigoInterno.trim());
    formData.append("nameImage",nameImage.trim());
    formData.append("notificaciones",medico.notificaciones.toString());
    return this.http.post(this.url+"editar-perfil",formData,{headers:headers});
  }


  //
  cambiarClave(id:number){
    let formData = new FormData();
    formData.append("id",id.toString());
    return this.http.post(this.url+"cambiar-clave",formData);
  }


  //
  cantidad(){
    return this.http.get(this.url+"cantidad");
  }


  //
  login(medico:Medico){
    const formData = new FormData(); 
    let key = encHex.parse("0123456789abcdef0123456789abcdef");
    let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
    let password = aes.encrypt(medico.password,key,{iv:iv, padding:padZeroPadding}).toString();
    formData.append("password",password);
    formData.append("email",medico.datoingreso);
    if(this.titulo_login == 'MAIL'){
      formData.append("email",medico.datoingreso);
    }
    else{
      formData.append("numeroDocumento",medico.datoingreso);
    }
    return this.http.post(this.url+"login",formData);
  }


  //
  emailContacto(id:number,texto:string,filesUpload:File[],fileSaleSize:number){
    const formData = new FormData();
    const headers = new HttpHeaders();
    
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    if(filesUpload){
      formData.append('cantidadArchivos',filesUpload.length.toString());
      for (let i = 0; i < filesUpload.length; i++) {
        formData.append('archivos'+i,filesUpload[i]);
      }
    }
    formData.append("id",id.toString());
    formData.append("texto",texto);
    formData.append("tipoUsuario","medico");
    console.log(id,texto);
    return this.http.post(this.url+"contacto",formData,{headers: headers});
  }


  // Levanta el token del localStorage
  getToken(){
    return localStorage.getItem('auth_token');
  }
  

  // Chequea que el token sea valido
  isValidToken(){
    return new Promise((resolve, reject) => {
    const token = this.getToken();
    if(token){
      const payload = this.payload(token);
      if(payload){
        resolve( this.url + 'login'  === payload.iss ? true : false)
      } 
      else {
        resolve( false)
      }
    }
  })
  }


  // Hace el parseo del jwt 
  payload(token) {
    const jwtPayload = token.split('.')[1];
    return JSON.parse(atob(jwtPayload));
  }

  // Chequea que el usuario este logueado
  isLoggedIn() {
    return this.isValidToken();
  }

  //setea el token en el local storage
  handleData(token){
    localStorage.setItem('auth_token', JSON.stringify(token));
  }
  
  medicoLogeado(): Observable<any> {
    return this.http.get(this.url + 'user-profile');
  }


  //
  datosAutorizacion(): Observable<any> {
    return this.http.get(this.url + 'datos-autorizacion');
  }


  //
  setDatosAutorizacion(data){
    localStorage.setItem('turno_habilitado', data.turnoHabilitado);
  }


  //
  deleteDatosAutorizacion(){
    localStorage.removeItem('turno_habilitado');
    localStorage.removeItem('auth_token');
  }


  //
  logout(){
    localStorage.removeItem('turno_habilitado');
    localStorage.removeItem('auth_token');
    this.router.navigate(['/auth/login-medico']);
  }

}
