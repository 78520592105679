<div class="panel">
    <div class="dashboard">
        <app-dashboard [usuarioPanel]="usuarioPanel"></app-dashboard>
    </div>
    <div class="panelContent">
        <app-header-panel [usuarioPanel]="usuarioPanel"></app-header-panel>
        <app-crear-form-paciente [response]="response" [editar]="editar" (listar)="listaActualizada($event)" *ngIf="!llegoLimitePacientes"></app-crear-form-paciente>
        <div class="divContent" *ngIf="llegoLimitePacientes">
            <div><mat-card class="errorSeccion">Ha llegado al límite de pacientes disponible para su plan. Por favor, contacte a CentralMed</mat-card></div>
        </div>
        <div class="seccionTable">
            <mat-card class="cardTable mat-elevation-z4">
                <div class="topTitle"><mat-card class="titleSeccion">Pacientes</mat-card></div>
                <mat-form-field class="example-full-width buscador" appearance="fill">
                    <mat-label>Buscador</mat-label>
                    <input placeholder="" #buscador (keyup)="filter($event)" [(ngModel)]="searchKey" type="text" matInput autocomplete="off">
                </mat-form-field>
                <table  class="" mat-table matSort [dataSource]="dataSource">
                    <ng-container matColumnDef="nombreApellido">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by nombreApellido"><span class="span">Nombre</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.nombreApellido}} </td>
                    </ng-container>
                    <ng-container matColumnDef="tipo">
                        <th mat-header-cell *matHeaderCellDef>Tipo Doc.</th>
                        <td mat-cell *matCellDef="let element"> {{element.texto}} </td>
                    </ng-container>
                    <ng-container matColumnDef="numDocumento">
                        <th mat-header-cell *matHeaderCellDef>Núm. Doc.</th>
                        <td mat-cell *matCellDef="let element"> {{element.numeroDocumento}} </td>
                    </ng-container>
                    <ng-container matColumnDef="codigoInterno">
                        <th mat-header-cell *matHeaderCellDef>Cód. Interno</th>
                        <td mat-cell *matCellDef="let element"> {{element.codigoInterno}} </td>
                    </ng-container>
                    <ng-container matColumnDef="obraSocial">
                        <th mat-header-cell *matHeaderCellDef>Obra Social</th>
                        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                    </ng-container>
                    <ng-container matColumnDef="numAfiliado">
                        <th mat-header-cell *matHeaderCellDef>Núm. Afi.</th>
                        <td mat-cell *matCellDef="let element"> {{element.numeroAfiliado}} </td>
                    </ng-container>
                    <ng-container matColumnDef="acciones">
                        <th mat-header-cell *matHeaderCellDef class="th">Acciones</th>
                        <td mat-cell *matCellDef="let element" class="td">
                            <div class="divIcons">
                                <div [routerLink]="['/seccion-panel/paciente/editar',element.id]" class="editar divIcon">
                                    <mat-icon class="icon">edit</mat-icon>
                                </div>
                                <div [routerLink]="['/seccion-panel/paciente/estudios-medicos',element.id]" class="ver divIcon">
                                    <mat-icon class="icon">visibility</mat-icon>
                                </div>
                                <div class="whatsapp divIcon">
                                    <a target="_blank" href="https://api.whatsapp.com/send?phone={{element.celular}}">
                                        <i class="icon wsp fa-brands fa-whatsapp"></i>
                                    </a>
                                </div>
                                <div (click)="cambiarPassword(element.id)" class="key divIcon">
                                    <mat-icon class="icon">vpn_key</mat-icon>
                                </div>
                                <div (click)="openDialog(element)" class="borrar divIcon">
                                    <mat-icon class="icon">delete_forever</mat-icon>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>   
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons 
                    aria-label="Select page of periodic elements">
                </mat-paginator> 
            </mat-card>        
        </div>
    </div>
</div>

