<div class="content-login">
    <mat-card class="example-card mat-elevation-z4">
        <div class="divImg">
            <img src="{{imagen}}" class="img"/>
        </div>

        <form class="example-form" [formGroup]="loginForm" (submit)="login(loginForm.value)">
            <div class="titleLogIn"><span>Ingresar - Paciente</span></div>
            <div class="divInput">
                <mat-form-field class="example-full-width" appearance="fill" class="input">
                    <mat-label>{{titulo_login}}</mat-label>
                    <input type="text" formControlName="datoingreso" matInput placeholder="">
                </mat-form-field>
            </div>
            <div class="divInput">
                <mat-form-field class="example-full-width" appearance="fill" class="input">
                    <mat-label>Password</mat-label>
                    <input type="password" formControlName="password" matInput placeholder="">
                </mat-form-field>
                <div class="recuperar">
                    <a class="recuperarEnlace" [routerLink]="['/auth/recuperar-contraseña-paciente']">Recuperar Password</a>
                </div>
            </div>

            <div class="divButton">
                <button type="submit" mat-raised-button color="primary" class="ingresar">Ingresar</button>
            </div>
        </form>
    </mat-card>
</div>

