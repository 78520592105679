import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { ElementRef,AfterViewInit,Component, OnInit,ViewChild,NgModule ,Inject } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { DeleteErrorDialogComponent } from 'app/shared/delete-error-dialog/delete-error-dialog.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared/confirm-dialog/confirm-dialog.component';
import { EstudioMedicoService } from 'app/services/estudio-medico.service';
import { EstudioMedico } from 'app/models/estudioMedico.interface'; 
import { SpinnerService } from 'app/services/spinner.service';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';



@Component({
  selector: 'app-estudio-medico-index',
  templateUrl: './estudio-medico-index.component.html',
  styleUrls: ['./estudio-medico-index.component.scss']
})
export class EstudioMedicoIndexComponent implements OnInit {


  //
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('buscador') buscador: ElementRef;
  displayedColumns: string[] = ['id', 'texto', 'acciones'];
  dataSource:any;
  data:EstudioMedico[];
  searchKey:string;
  editar:boolean = false;
  usuarioPanel:any;


  //
  constructor(
    public dialog: MatDialog,
    private usuarioPanelService:UsuarioPanelService,
    private spinnerService:SpinnerService,
    private estudioMedicoService:EstudioMedicoService,
    private snackBar:MatSnackBar
  ) { 
    this.spinnerService.mostrarSpinner();
    this.usuarioPanelService.profileUser().pipe(
      map(data => {
        this.usuarioPanel = data;
      }),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        this.usuarioPanelService.logout();
        return of({ results: null });
      }),
      switchMap(data => this.estudioMedicoService.listar().pipe(
        map(data => { 
          this.listar(data);
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return of({ results: null });
      }),
    )
    .subscribe(
      (data) => console.log('data',data),
      (error) => console.error('Error al llamar a la API:', error)
    );
  }


  //
  ngOnInit(): void {}


  //
  openDialog(element:EstudioMedico){
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      width: '50%',
      data: 'Estudio médico'
    })
    dialogRef.afterClosed().subscribe(res=>{
      if(res){
        this.borrar(element);
      }
    });
  }


  //
  listar = (data:any) => {
    this.data = data;
    this.dataSource = new MatTableDataSource<EstudioMedico>(data);
    this.dataSource.paginator = this.paginator;
  }


  //
  openBorrarErrorDialog = () => { 
    let object = {
      titulo: 'estudios médicos',
      mensaje: 'No se puede eliminar estudios médicos asignados a pacientes'
    }
    const dialogRef = this.dialog.open(DeleteErrorDialogComponent,{
      width: '50%',
      data: object
    });
    dialogRef.afterClosed().subscribe(res=>{});
  }


  //
  borrar = (EstudioMec:EstudioMedico) => {
    this.spinnerService.mostrarSpinner();
    this.estudioMedicoService.borrar(EstudioMec).pipe(
      map(data => {}),
      catchError(error => {
        if(error.error.errorEstudioMedico){
          this.openBorrarErrorDialog();
        }
        this.spinnerService.ocultarSpinner();
        return error;
      }),
      switchMap(data => this.estudioMedicoService.listar().pipe(
        map(data => { 
          this.listar(data);
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return error;
      }),
    )
    .subscribe(
      (data) => console.log('data',data),
      (error) => console.error('Error al llamar a la API:', error)
    );
  }


  //
  listaActualizada = (mensaje:boolean) => {
    this.estudioMedicoService.listar().subscribe(
      data => {
        this.listar(data);
        this.spinnerService.ocultarSpinner();
      },
      error => {
        this.spinnerService.ocultarSpinner();
      }
    )
  }


  //
  filter = (event:any) => {
    let dataFilter:EstudioMedico[] = [];
    let valorString:string = this.buscador.nativeElement.value;
    this.data.forEach(obj=>{
      if(obj.id.toString().includes(valorString)){
        dataFilter.push(obj);
      }
      else if(obj.texto.toUpperCase().includes(valorString.toUpperCase())){
        dataFilter.push(obj);
      } 
    })
    this.dataSource = new MatTableDataSource<EstudioMedico>(dataFilter);
    this.dataSource.paginator = this.paginator;
  }


  //
  openSnackBar = (message:string, action:string) => {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message: message,
        action: action
      },
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [action]
    });
  }


}
