import { ElementRef,AfterViewInit,Component, OnInit,ViewChild,NgModule  } from '@angular/core';
import { CentroMedico } from 'app/models/centroMedico.interface';
import { SpinnerService } from 'app/services/spinner.service';
import { TelefonoPaisService } from 'app/services/telefono-pais.service';
import { CentroMedicoService } from 'app/services/centro-medico.service';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { Router,ActivatedRoute } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-editar-sucursal',
  templateUrl: './editar-sucursal.component.html',
  styleUrls: ['./editar-sucursal.component.scss']
})
export class EditarSucursalComponent implements OnInit {


  //
  editar:boolean = true;
  listaTelefonos:any;
  usuarioPanel:any;
  sucursal:any;
  response:any;


  //
  constructor(
    private spinnerService:SpinnerService,
    private usuarioPanelService:UsuarioPanelService,
    private telefonoPaisService:TelefonoPaisService,
    private sucursalService:CentroMedicoService,
    private actRoute: ActivatedRoute,
  ) { 
    this.spinnerService.mostrarSpinner();
    let id = this.actRoute.snapshot.paramMap.get("id");
    this.usuarioPanelService.profileUser().pipe(
      map(data => {
        console.log("data1: ", data);
        this.usuarioPanel = data;
      }),
      catchError(error => {
        console.log("Error from 1 call: ", error);
        this.spinnerService.ocultarSpinner();
        this.usuarioPanelService.logout();
        return of({ results: null });
      }),
      switchMap(data => this.sucursalService.mostrar(id).pipe(
        map(data => { 
          console.log("data2: ", data);
          this.response = data;
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        console.log("Error from 2 call: ", error);
        this.spinnerService.ocultarSpinner();
        return of({ results: null });
      }),
    )
    .subscribe(
      (data) => {
        console.log('data',data);
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
      }
    );
    
    /*
    this.usuarioPanelService.profileUser().pipe(
      tap(data => {
        this.usuarioPanel = data;
      }),
      switchMap((data) => this.telefonoPaisService.listar().pipe(
        map(data => { 
          console.log('telefonos',data); 
          this.listaTelefonos = data;
        })
      )),
      switchMap((data) => this.sucursalService.mostrar(id).pipe(
        map(data => { 
          console.log('mostrar',data); 
          this.sucursal = data;
          //this.listaTelefonos = data;
        })
      ))
    )
    .subscribe((data) => {
        console.log('data',data);
        this.spinnerService.ocultarSpinner();
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
        this.spinnerService.ocultarSpinner();
      }
    );
    */
  }


  //
  ngOnInit(): void {}

  
}
