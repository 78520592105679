import { Component, OnInit } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { UsuarioPanel } from 'app/models/usuarioPanel.interface';
import * as CryptoJS from 'crypto-js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { SpinnerService } from 'app/services/spinner.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-login-panel',
  templateUrl: './login-panel.component.html',
  styleUrls: ['./login-panel.component.scss']
})
export class LoginPanelComponent implements OnInit {


  //
  loginForm:FormGroup;
  usuarioPanel:UsuarioPanel;
  response:any;


  //
  constructor(
    private router:Router,
    private usuarioPanelService:UsuarioPanelService,
    private snackBar:MatSnackBar,
    private spinnerService:SpinnerService
  ) { }


  //
  ngOnInit(): void {
    this.loginForm = new FormGroup({
      nombreUsuario : new FormControl('',Validators.required),
      password : new FormControl('',Validators.required)
    })
  }


  //
  login(event){
    this.spinnerService.mostrarSpinner();
    this.usuarioPanelService.login(event).pipe(
      map(data => {
        let response:any = data;
        let dataToken = { token: response.token };
        this.usuarioPanelService.handleData(dataToken);
      }),
      catchError(error => {
        this.usuarioPanelService.deleteDatosAutorizacion();
        this.spinnerService.ocultarSpinner();
        this.openSnackBar("Ingreso fallido: " + error.error.error,"error");
        return error;
      }),
      switchMap(data => this.usuarioPanelService.datosAutorizacion().pipe(
        map(data => { 
          let response:any = data;
          this.usuarioPanelService.setDatosAutorizacion(response);
          this.router.navigate(['/seccion-panel/home-panel/']);
        }),
      )),
      catchError(error => {
        this.usuarioPanelService.deleteDatosAutorizacion();
        this.spinnerService.ocultarSpinner();
        this.openSnackBar("Ingreso fallido: " + error.error.error,"error");
        return error;
      })
    )
    .subscribe(
      (data) => {
        console.log('data',data);
      },
      (error) => {
        console.log('error',error);
      }
    );
  }


  //
  openSnackBar(message: string, action: string) {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message: message,
        action: action
      },
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [action]
    });
  }
}
