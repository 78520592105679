<div class="panel">
    <div class="dashboard">
        <div class="dashboardContent mat-elevation-z4">
            <app-dashboard [usuarioPanel]="usuarioPanel"></app-dashboard>
        </div>
    </div>
    <div class="panelContent">
        <app-header-panel [usuarioPanel]="usuarioPanel"></app-header-panel>
        <app-informe-creaciones-por-anio [titulo]="tituloPacientesPorAnio" [descripcion]="descPacientesPorAnio" [informesPorAnio]="pacientesPorAnio"></app-informe-creaciones-por-anio>
        <app-informe-creaciones-por-anio [titulo]="tituloEstudiosMedicosPacientesPorAnio" [descripcion]="descEstudiosMedicosPacientesPorAnio" [informesPorAnio]="estudiosMedicosPacientesPorAnio"></app-informe-creaciones-por-anio>
        <app-informe-creaciones-por-anio [titulo]="tituloTurnosPorAnio" [descripcion]="descPacientePorAnio" [informesPorAnio]="turnosPorAnio"></app-informe-creaciones-por-anio>
        <app-informe-grafico-torta [titulo]="tituloEstudiosMedicosTorta" [descripcion]="descEstudiosMedicosTorta" [informeGrafico]="estudiosMedicosPorcentaje"></app-informe-grafico-torta>
        <app-informe-grafico-torta [titulo]="tituloTurnosEspecialidadTorta" [descripcion]="descTurnosEspecialidadTorta" [informeGrafico]="turnosPorcentaje"></app-informe-grafico-torta>
        <app-informe-top-medicos [titulo]="tituloTopMedicos" [descripcion]="descTopMedicos" [topMedicos]="topMedicos"></app-informe-top-medicos>
    </div>
</div>