import { PacienteService } from './../../services/paciente.service';
import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { MedicoService } from 'app/services/medico.service';
import { SpinnerService } from 'app/services/spinner.service';


@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss']
})
export class PasswordDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public mensaje: any,
    private apiUsuarioPanel:UsuarioPanelService,
    private apiMedico:MedicoService,
    private apiPaciente:PacienteService,
    private spinnerService:SpinnerService,
  ) { }

  response:boolean = false;
  password:string; 

  ngOnInit(): void {
    this.response = false;
    this.password = "";
  }

  cambiar(){
    this.mensaje.nombre
    if(this.mensaje.nombre == "usuarioPanel"){
      this.spinnerService.mostrarSpinner();
      this.apiUsuarioPanel.cambiarClave(this.mensaje.id).subscribe(
        data=>{
          this.response = true;
          this.password = data.toString();
          this.spinnerService.ocultarSpinner();
        }
      )
    }
    else if(this.mensaje.nombre == "medico"){    
      this.spinnerService.mostrarSpinner();
      this.apiMedico.cambiarClave(this.mensaje.id).subscribe(
        data=>{
          this.response = true;
          this.password = data.toString();
          this.spinnerService.ocultarSpinner();
        }
      )
      
    }
    else if(this.mensaje.nombre == "paciente"){
      this.spinnerService.mostrarSpinner();
      this.apiPaciente.cambiarClave(this.mensaje.id).subscribe(
        data=>{
          this.response = true;
          this.password = data.toString();
          this.spinnerService.ocultarSpinner();
        }
      )
    }
  }

  onClickNo(){
    this.dialogRef.close();
  }
}
