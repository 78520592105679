<div class="plan">
    <div class="contenPlan"> 
        <mat-card class="card mat-elevation-z4">
            <div class="topTitle">
                <mat-card class="titleSeccion">Plan</mat-card>
            </div>
            <form [formGroup]="planForm" class="contenidoPrincipal primerContenido">
                <div class="att">Nombre plan 
                <mat-form-field class="example-full-width input" appearance="fill">
                    <mat-label>Nombre plan</mat-label>
                    <input type="text" readonly formControlName="nombrePlan" matInput>
                </mat-form-field>
                </div> 
            </form>
            <form [formGroup]="planForm" class="contenidoPrincipal">
                <div class="att">Cantidad de usuarios
                    <mat-form-field class="example-full-width input" appearance="fill">
                        <mat-label>Cantidad de usuarios</mat-label>
                        <input type="text" readonly formControlName="cantidadUsuario" matInput>
                    </mat-form-field>
                </div> 
            </form>
            <form [formGroup]="planForm" class="contenidoPrincipal">
                <div class="att">Espacio máximo (GB) 
                    <mat-form-field class="example-full-width input" appearance="fill">
                        <mat-label>Espacio máximo (GB)</mat-label>
                        <input type="text" readonly formControlName="espacioMaximo" matInput>
                    </mat-form-field>
                </div> 
            </form>
            <form [formGroup]="planForm" class="contenidoPrincipal">
                <div class="att">Cantidad agendas 
                    <mat-form-field class="example-full-width input" appearance="fill">
                        <mat-label>Cantidad agendas</mat-label>
                        <input type="text" readonly formControlName="cantidadAgendas" matInput>
                    </mat-form-field>
                </div> 
            </form>
            <form [formGroup]="planForm" class="contenidoPrincipal">
                <div class="att">Landing 
                    <mat-form-field class="example-full-width input" appearance="fill">
                        <mat-label>Landing</mat-label>
                        <input type="text" readonly formControlName="landing" matInput>
                    </mat-form-field>
                </div> 
            </form>
        </mat-card>
    </div>
</div>