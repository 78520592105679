import { Component, OnInit } from '@angular/core';
import { PlanService } from 'app/services/plan.service';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { SpinnerService } from 'app/services/spinner.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-home-panel',
  templateUrl: './home-panel.component.html',
  styleUrls: ['./home-panel.component.scss']
})
export class HomePanelComponent implements OnInit {


  //
  response:any;
  plan:any;
  indicadores:any;
  usuarioPanel:any;


  //
  constructor(
    private planService:PlanService, 
    private usuarioPanelService:UsuarioPanelService,
    private spinnerService:SpinnerService
  ) {}


  //
  ngOnInit(): void {
    this.spinnerService.mostrarSpinner();
    this.usuarioPanelService.profileUser().pipe(
      map(data => {
        this.usuarioPanel = data;
      }),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        this.usuarioPanelService.logout();
        return of({ results: null });
      }),
      switchMap(data => this.planService.mostrarPlanCantidad().pipe(
        map(data => { 
          this.response = data;
          this.plan = this.response.plan;
          this.indicadores = this.response.indicadores;
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return of({ results: null });
      }),
    )
    .subscribe(
      (data) => {
        console.log('data',data);
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
      }
    );
  }

  
}
